import React from "react";
import Carousel from "react-material-ui-carousel";
import sanitize from "sanitize-html";
import styled from "styled-components";
import * as marked from "marked";
import { colors } from "../../styles/colors";
import {
  Callout,
  CalloutContentWrapper,
  TextContent,
  Title,
} from "../../styles/StyledComponents";
import {
  BlockContent,
  BlockContentComponent,
  EventPage,
} from "../../types/eventPage";
import { useDetectDevice } from "../../util/hooks";
import FaqContainer from "../Faq/FaqContainer";
import Video from "../Video/Video";
import CarouselItem from "./CarouselItem";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const EventButton = styled.a`
  font-size: 16pt;
  border: none;
  align-self: center;
  color: ${colors.white};
  background-color: ${colors.orange};
  min-width: 120px;
  padding: 10px;
  text-align: center;
  display: inline-block;
  cursor: pointer;
  opacity: 0.95;
  user-select: none;
  text-decoration: none;
  &:hover {
    opacity: 1;
  }
`;

const BottleMapContainer = styled.div`
  position: relative;
  height: 50vh;
  width: 800px;

  @media only screen and (max-width: 991px) {
    height: 80vh;
    width: 100%;
    margin-left: 0px;
    margin-top: 10px;
  }

  @media only screen and (max-width: 810px) {
    height: 45vh;
  }

  @media only screen and (max-width: 478px) {
    width: 100%;
    height: 25vh;
    margin-top: 15px;
  }
`;

const EventSection = styled(Callout)`
  display: grid;
  grid-template-columns: repeat(auto-fill, 1fr);
  row-gap: 20px;
  @media only screen and (max-width: 810px) {
    display: block;
  }
`;

const EventContainer = ({ event }: { event: EventPage }) => {
  const { width } = useDetectDevice();
  const selectBlockComponent = (
    blockContent: BlockContent,
    typename: BlockContentComponent
  ) => {
    switch (typename) {
      case "ContentfulMap":
        switch (blockContent.mapType) {
          case "bottle-map":
            return (
              <BottleMapContainer>
                <iframe
                  frameBorder="none"
                  style={{ width: "100%", height: "100%", border: "none" }}
                  title="Debris Tracker Bottle Map"
                  src="https://bottle.debristracker.org"
                />
              </BottleMapContainer>
            );
        }
      case "ContentfulBlockText":
        const parsedPost = JSON.parse(blockContent.text.raw);
        const components = documentToReactComponents(parsedPost);
        return (
          <CalloutContentWrapper>
            <TextContent>{components}</TextContent>
          </CalloutContentWrapper>
        );
      case "ContentfulFaqSection":
        const data: any[] = blockContent.faqQuestions.map((qa) => {
          return { question: qa.question, answer: qa.answer?.answer };
        });
        return (
          <>
            <CalloutContentWrapper>
              <Title centered level={2} color="#536275">
                Frequently Asked Questions
              </Title>
            </CalloutContentWrapper>
            <CalloutContentWrapper>
              <FaqContainer data={data} />
            </CalloutContentWrapper>
          </>
        );
      case "ContentfulVideo":
        return (
          <Video
            width={width < 800 ? width - 30 : 800}
            height={474}
            videoSrcURL={blockContent.url}
            videoTitle={"Video"}
          />
        );
      case "ContentfulCarousel":
        let carouselContent: any[];
        if (blockContent.resources) {
          carouselContent = blockContent.resources;
        } else if (blockContent.images) {
          carouselContent = blockContent.images;
        }
        return (
          <Carousel
            animation="slide"
            indicators={false}
            timeout={500}
            navButtonsAlwaysVisible={width >= 767}
            swipe={width <= 767}
          >
            {carouselContent?.map((resource, i) => (
              <CarouselItem key={i} data={resource} />
            ))}
          </Carousel>
        );
      case "ContentfulDownloadableBlock":
        return (
          <CalloutContentWrapper vertical>
            {blockContent.downloadables?.map((downloadable, i) => {
              return (
                <EventButton
                  target="_blank"
                  href={`https:${downloadable.resource?.file?.url}`}
                  key={i}
                  style={{ width: "100%", marginBottom: "10px" }}
                >
                  {downloadable.buttonTitle}
                </EventButton>
              );
            })}
          </CalloutContentWrapper>
        );
    }
  };

  return (
    <div>
      {event.sections.map((section, i) => (
        <EventSection key={i} type={section.backgroundColor}>
          {section.contentBlocks?.map((block, index) => {
            return (
              <CalloutContentWrapper key={index} vertical={true}>
                {block.title && <Title>{block.title}</Title>}
                {selectBlockComponent(
                  block.blockContent,
                  block.blockContent.__typename
                )}
              </CalloutContentWrapper>
            );
          })}
        </EventSection>
      ))}
    </div>
  );
};

export default EventContainer;
