import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout/Layout";
import Seo from "../components/seo";
import EventContainer from "../components/Event/EventContainer";

const EventPage = (props) => {
  const { contentfulEventPage: event } = props.data;
  return (
    <Layout vertical={true}>
      <Seo title={event.title} />
      <EventContainer event={event} />
    </Layout>
  );
};

export default EventPage;

export const pageQuery = graphql`
  query EventPageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulEventPage(slug: { eq: $slug }) {
      title
      sections {
        backgroundColor
        contentBlocks {
          title
          blockContent {
            __typename
            ... on ContentfulBlockText {
              text {
                raw
              }
            }
            ... on ContentfulCarousel {
              resources {
                image {
                  gatsbyImageData
                }
                description {
                  description
                }
                title
                url
              }
              images {
                gatsbyImageData
              }
            }
            ... on ContentfulDownloadableBlock {
              downloadables {
                buttonTitle
                resource {
                  title
                  file {
                    fileName
                    url
                  }
                }
              }
            }
            ... on ContentfulFaqSection {
              faqQuestions {
                question
                answer {
                  answer
                }
              }
            }
            ... on ContentfulMap {
              mapType
              mapId
            }
            ... on ContentfulVideo {
              url
            }
          }
        }
      }
    }
  }
`;
