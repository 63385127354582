import React from "react";
import styled from "styled-components";
import { HighlightImage, ResourcesEntity } from "../../types/eventPage";

const CarouselContainer = styled.a`
  display: flex;
  position: relative;
  width: 800px;
  height: 474px;
  cursor: pointer;

  @media only screen and (max-width: 991px) {
    margin-top: 20px;
    width: 95vw;
    width: 100%;
  }

  @media only screen and (max-width: 767px) {
    width: 100%;
  }

  @media only screen and (max-width: 478px) {
    width: 100%;
    height: 40vh;
  }
`;

const CarouselItem = ({ data }: { data: ResourcesEntity }) => {
  return (
    <CarouselContainer href={data.name} target="__blank">
      <img
        style={{
          height: "100%",
          width: "100%",
          position: "absolute",
          objectFit: "cover",
        }}
        srcSet={
          data.image?.gatsbyImageData?.images?.sources[0]?.srcSet ||
          ((data as unknown) as HighlightImage).gatsbyImageData.images // this is horrible - i'm so sorry
            ?.sources[0].srcSet
        }
        alt={data.name}
      />
      {data.description?.description && (
        <div
          style={{
            backgroundColor: "#00000085",
            position: "absolute",
            bottom: "0",
            right: "0",
            left: "0",
            width: "100%",
            padding: "20px",
          }}
        >
          <div
            style={{
              color: "white",
              fontSize: "20px",
              opacity: 1,
            }}
          >
            {data.name}
          </div>
          <div style={{ color: "white" }}>{data.description.description}</div>
        </div>
      )}
    </CarouselContainer>
  );
};

export default CarouselItem;
